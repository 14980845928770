import React, { useContext } from 'react'
import LibraryWrapper from './LibraryWrapper'
import { ImpactContext } from '../../components/Layout';
import { Entity } from '@parallelpublicworks/entitysync/';
import { 
    SelectField
} from '../../components/library/entitysync'; 

const sample_options = [
    {
        value: 'they_them',
        label: 'They/Them',
    },
    {
        value: 'she_her',
        label: 'She/her',
    },
    {
        value: 'he_him',
        label: 'He/Him',
    },
]

function inputSelect() {
    const {user} = useContext(ImpactContext)
    return (
        <LibraryWrapper>
            <Entity componentId="user-entity" type="user--user" source={user}>
                <SelectField 
                    field="sample_select"
                    label="Sample Select"
                    onChange={(e) => console.log('SELECT CHANGED')}
                >
                    {sample_options.map((option) => (
                        <option key={option.value} value={option.value}>{option.label}</option>
                    ))}
                </SelectField>
                <hr />
                <SelectField 
                    field="sample_select_two" 
                    label="Sample Select" 
                    error 
                    helperText="Please select a pronoun" 
                >
                    {sample_options.map((option) => (
                        <option key={option.value} value={option.value}>{option.label}</option>
                    ))}
                </SelectField>
                
            </Entity>
        </LibraryWrapper>
    )
}
export default inputSelect
